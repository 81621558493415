import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import { HTMLContent } from "../components/Content";
import Layout from "../components/layout";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";

const TextPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { image } = post.frontmatter;

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        meta_title={post.frontmatter.meta_title}
        meta_desc={post.frontmatter.meta_description}
        slug={post.fields.slug}
      />
      <main className="py-8 px-6">
        <div
          className={classNames(
            { "grid grid-cols-1 md:grid-cols-4 gap-x-10": image },
            "max-w-screen-lg mx-auto"
          )}
        >
          <HTMLContent
            className={classNames({ "col-span-1 md:col-span-3": image })}
            content={post.html}
          />
          {image && (
            <GatsbyImage
              className="object-cover col-span-1 mt-20 m-auto"
              image={image.childImageSharp.gatsbyImageData}
              alt=""
              objectFit="contain"
              objectPosition="0 0"
            />
          )}
        </div>
      </main>
    </Layout>
  );
};

export default TextPage;

export const textPageQuery = graphql`
  query TextPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 290, quality: 100, layout: CONSTRAINED)
          }
        }
        meta_title
        meta_description
      }
    }
  }
`;
