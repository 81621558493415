import classNames from "classnames";
import React from "react";
import "./contentStyle.css";

export const HTMLContent = ({ content, className }) => (
  <div
    className={classNames("html-content", className)}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
);

export default Content;
